@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.mentions {
  @apply text-sm p-4 block ring-0 w-full resize-none border-0 bg-transparent py-1.5  placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6;
}

.mentions--multiLine .mentions__control {
  @apply bg-transparent;
}

.mentions--multiLine .mentions__highlighter {
  min-height: 1rem;
  border: 0px !important;
  color: white;
}

.mentions--multiLine .mentions__input {
  @apply text-sm p-4 block ring-0 w-full resize-none border-0 bg-transparent py-1.5  placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6;
}

.mentions__suggestions__list {
  @apply bg-white text-gray-500 rounded shadow-lg;
}

.mentions__suggestions__item {
  font-weight: 800 !important;
  @apply px-2 py-1.5 rounded text-gray-600 text-sm font-semibold;
}

.mentions__suggestions__item--focused {
  @apply bg-persian-100 text-persian-500;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: #7b18ff;
  border: 0px !important;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: none;
  pointer-events: none;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1;
  /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

blockquote {
  padding: 12px;
  color: #6b7280;
  font-weight: 500;
  border-width: 1px;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  margin-top: 8px;
  margin-bottom: 8px;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    /* all element selector */
    box-sizing: border-box;
    /* border, within the width of the element */
  }

  input {
    display: block;
    width: 100%;
  }

  /* Main toolbar */
  #toolbarViewer #print,
  #toolbarViewer #download {
    display: none !important;
  }

  /* Secondary toolbar (drop-down) */
  #secondaryToolbar #secondaryPrint,
  #secondaryToolbar #secondaryDownload {
    display: none !important;
  }

  .fade-slide-down-enter {
    opacity: 0;
    transform: translateY(-20px);
  }

  .fade-slide-down-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }

  .fade-slide-down-exit {
    opacity: 1;
  }

  .fade-slide-down-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
}
